/*global ajax_object*/
// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
function greenText() {
  $('.post-title').each(function () {
    var text = $(this).text();
    var newtext = '';
    var insideAsterisks = false;

    for (var i = 0; i < text.length; i++) {
      if (text[i] == '*') {
        insideAsterisks = !insideAsterisks; // Toggle the insideAsterisks flag
        if (insideAsterisks) {
          newtext += '<span class="green-text">';
        } else {
          newtext += '</span>';
        }
      } else {
        newtext += text[i];
      }
    }

    $(this).html(newtext);
  });
}

$(document).on('ready', function () {
  greenText();

  $('.menu-link-news a').each(function () {
    var text = $(this).text().split(' ');
    var newText = '';

    for (var i = 0; i < text.length; i++) {
      newText += '<span class="word-' + i + '">' + text[i] + '</span> ';
    }

    $(this).html(newText.trim());
  });
  if ($('.nav-links .nav-previous').length == 0) {
    $('.nav-links').css('justify-content', 'flex-end');
  }

  $('.testimonials-slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    // appendDots: '.slick-dots',
    // customPaging: function (slider, i) {
    //   return '<button>' + (i + 1) + '</button>';
    // },
    arrows: true,
  });
  $('.share__link').click(function (e) {
    e.preventDefault();
    var wpWidth = $(window).width(),
      wpHeight = $(window).height();
    window.open(
      $(this).attr('href'),
      'Share',
      'top=' +
        (wpHeight - 400) / 2 +
        ',left=' +
        (wpWidth - 600) / 2 +
        ',width=600,height=400'
    );
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  // var posts_num = $('.more-posts-link').find('.load-more').attr('data-count');
  function ajaxCatFilter(data) {
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: data,
      beforeSend: function () {
        $('.posts-list').addClass('loading');
      },
      success: function (result) {
        $('.posts-list').empty();
        $('.posts-list').html(result);
        $('.filter-result').empty();
        $('.filter-result').html($('.filter-result__container'));

        // if (ajax_object.current_page >= posts_num) {
        //   $('.more-posts-link').hide();
        // }
        greenText();
      },
      error: function () {
        console.log('error');
      },
    });
  }

  if ($('.fxy').length) {
    var categoriesArray = [];
    $('.category-select').on('change', function () {
      $(this).find('option').removeClass('selected');
      $(this).find('option:selected').addClass('cat-selected');
      $('.list-of-posts').addClass('filtered');

      let selectedCategories = $(this).val(); // This will return an array of selected category IDs
      let postList = $('.posts-list');
      if (!categoriesArray.includes(selectedCategories)) {
        categoriesArray.push(selectedCategories);
      }
      postList.attr('categories', categoriesArray);

      const data = {
        action: 'cat_filter',
        // per_page: '4',
        categories: categoriesArray, // Send the array of selected categories
      };
      // console.log(categoriesArray);
      ajaxCatFilter(data);
    });
    $(document).on('click', '.cat-filter-empty', function () {
      let catRemove = $(this).closest('.category-list-item').data('category');
      categoriesArray = categoriesArray.filter(
        (item) => item !== catRemove.toString()
      );
      console.log(catRemove);

      const data = {
        action: 'cat_filter',
        // per_page: '4',
        categories: categoriesArray, // Send the array of selected categories
      };
      ajaxCatFilter(data);

      if (categoriesArray.length == 0) {
        $('.list-of-posts').removeClass('filtered');
        const data = {
          action: 'cat_filter',
          // per_page: '4',
          categories: -1, // Send the array of selected categories
        };
        ajaxCatFilter(data);
      }
    });
    $('.btn-paginatoion').on('click', function () {
      var paged = $(this).attr('data-index');
      $('.btn-paginatoion').removeClass('current');
      $(this).addClass('current');
      // console.log(paged);
      const data = {
        action: 'cat_filter',
        // per_page: '4',
        categories: categoriesArray,
        // max_num_pages: current_page + 1,
        paged: paged, // Send the array of selected categories
      };
      ajaxCatFilter(data);
    });
    $('.prev').on('click', function () {
      var currentId = $('.btn-paginatoion.current').data('index');
      var prevId = currentId - 1;
      var prevButton = $('#pagination-' + prevId);

      if (prevButton.length > 0) {
        $('.btn-paginatoion.current').removeClass('current');
        prevButton.addClass('current');
        const data = {
          action: 'cat_filter',
          categories: categoriesArray,
          paged: prevId,
        };
        ajaxCatFilter(data);
      }
    });

    $('.next').on('click', function () {
      var currentId = $('.btn-paginatoion.current').data('index');
      var nextId = currentId + 1;
      var nextButton = $('#pagination-' + nextId);

      if (nextButton.length > 0) {
        $('.btn-paginatoion.current').removeClass('current');
        nextButton.addClass('current');
        const data = {
          action: 'cat_filter',
          categories: categoriesArray,
          paged: nextId,
        };
        ajaxCatFilter(data);
      }
    });
  }
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
